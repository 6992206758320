import CashFlow from './CashFlow.vue'
import CashFlowCreate from './Create.vue'
import CashFlowEdit from './Create.vue'

export default [
    {
        path: '/cashFlow',
        name: 'CashFlow',
        component: CashFlow
    },
    {
        path: '/cashFlow/create',
        name: 'CashFlowCreate',
        component: CashFlowCreate
    },
    {
        path: '/cashFlow/edit/:id',
        name: 'CashFlowEdit',
        component: CashFlowEdit
    },
]
